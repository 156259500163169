<script setup lang="ts">
// @ts-ignore
import VLazyImage from "v-lazy-image";
import { useLinkDetailsStore } from "../../stores/linkDetails";

const linkDetailsStore = useLinkDetailsStore();
</script>

<template>
  <div class="custom-shadow relative rounded-2xl pb-6">
    <v-lazy-image
      class="max-h-[84px] max-w-[170px]"
      :src="linkDetailsStore.customisation.logoUrl"
      alt="logo"
    />
    <div class="gradient-overlay rounded-2xl"></div>
  </div>
</template>

<style scoped></style>
