<script setup lang="ts">
export interface Tab {
  label: string;
  target: string;
  isActive: boolean;
}

defineProps<{
  tabs: Tab[];
}>();

const emit = defineEmits(["assetTypeSelected", "toggleTo"]);

function onClick(tab: Tab) {
  emit("toggleTo", tab);
  emit("assetTypeSelected", tab.label);
}
</script>

<template>
  <div class="w-full print:hidden">
    <div class="border-b border-gray-200">
      <ul class="scroll text-center" role="tablist">
        <li
          v-for="tab in tabs"
          :key="tab.target"
          class="mr-6 inline text-sm last:mr-0"
          role="presentation"
        >
          <button
            @click="onClick(tab)"
            :id="`${tab.target}-tab`"
            :class="[
              'inline-block py-4',
              tab.isActive && 'border-b-2 border-gray-800 font-semibold',
            ]"
            type="button"
            role="tab"
            :aria-controls="tab.target"
            :aria-selected="tab.isActive"
          >
            {{ tab.label }}
          </button>
        </li>
      </ul>
    </div>
    <div>
      <div
        v-for="tab in tabs"
        :key="tab.target"
        :class="tab.isActive ? 'block' : 'hidden'"
      >
        <slot :name="tab.target"></slot>
      </div>
    </div>
  </div>

  <div class="hidden print:block" aria-hidden="true">
    <div v-for="tab in tabs" :key="tab.target">
      <div class="border-b border-gray-200">
        <ul class="-mb-px flex flex-wrap text-center" role="tablist">
          <li role="presentation">
            <button
              class="inline-block border-b-2 border-gray-800 py-4 font-semibold"
              type="button"
            >
              {{ tab.label }}
            </button>
          </li>
        </ul>
      </div>
      <div>
        <div>
          <slot :name="tab.target"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.how-to-text p {
  margin-bottom: 1rem;
}
.how-to-text p:last-child {
  margin-bottom: 0;
}
.scroll {
  display: inline-block;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
  white-space: nowrap;
  margin-bottom: -9px;
  -webkit-overflow-scrolling: touch;
}
.scroll li {
  -webkit-overflow-scrolling: touch;
  vertical-align: top;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
