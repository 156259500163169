export type SortOption = {
  code: string;
  name: string;
};

export const SORT_RECOMMENDED_CODE = "recommended";
export const SORT_ASCENDING_CODE = "asc";
export const SORT_DESCENDING_CODE = "dsc";

export function sortOptions(): SortOption[] {
  const options = new Array<SortOption>();

  options.push();

  return [
    { name: "Recommended", code: "recommended" },
    { name: "A-Z", code: "asc" },
    { name: "Z-A", code: "dsc" },
  ];
}
