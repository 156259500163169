<script setup lang="ts">
// @ts-ignore
import { onBeforeUnmount, onBeforeMount, withDefaults } from "vue";
import { CloseButton } from "../index";

const { textAlign, fullHeightMobile } = withDefaults(
  defineProps<{
    title?: string;
    closable: boolean;
    size?: "small" | "large";
    overlay?: boolean;
    textAlign?: "left" | "center" | "right";
    headerImageFilePath?: string;
    fullHeightMobile?: boolean;
    classNameOverlay?: string;
    classNameContent?: string;
  }>(),
  {
    fullHeightMobile: true,
    textAlign: "left",
  }
);

const emit = defineEmits(["onCloseClick"]);
const fullHeightStyle = fullHeightMobile ? "flex-1" : "";

function onCloseClick() {
  emit("onCloseClick");
}

onBeforeMount(() => {
  if (window.scrollY !== 0) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
  }
});

onBeforeUnmount(() => {
  const scrollY = document.body.style.top;
  document.body.style.position = "";
  document.body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
});
</script>

<template>
  <div
    :class="[
      `fixed inset-0 z-50 flex flex-col items-start justify-center bg-black bg-opacity-70 md:flex-row md:p-10 text-${textAlign}`,
      overlay && 'pt-[40%]',
      classNameOverlay && classNameOverlay,
    ]"
  >
    <div
      :class="[
        'self-baseline bg-white p-4 pb-0 md:mx-4 md:rounded-xl md:p-6 md:shadow-lg',
        `flex ${fullHeightStyle} max-h-full w-full`,
        'md:m-auto md:max-h-[80%]',
        size === 'small' ? 'md:max-w-md' : 'md:max-h-[100%] md:max-w-2xl',
        overlay && 'slide-in rounded-t-xl md:!animate-none',
        classNameContent && classNameContent,
      ]"
    >
      <div class="flex w-full flex-col">
        <img
          v-if="headerImageFilePath"
          :src="headerImageFilePath"
          class="mb-6 flex self-center"
        />
        <div v-if="!!title || closable" class="mb-3 shrink-0">
          <div class="relative">
            <h1
              :class="[
                'font-f37lineca w-full text-xl',
                size === 'large' && 'text-center',
              ]"
            >
              {{ title }}
            </h1>
            <CloseButton
              v-if="closable"
              class="absolute -top-1.5 right-0"
              @click="onCloseClick"
            ></CloseButton>
          </div>
        </div>

        <div
          :class="`flex flex-col ${fullHeightStyle} scroll-pr-2 overflow-y-auto`"
        >
          <div :class="`${fullHeightStyle} mb-6`">
            <slot name="body"></slot>
          </div>

          <div class="mb-4 flex justify-center md:mb-0">
            <slot name="button"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-in {
  animation: slide-in 0.3s linear;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}
</style>
