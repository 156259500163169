function isPartialSuccessMessage(msg: string | undefined) {
  const PARTIAL_SUCCESS_KEY_TERM_FROM_SCION =
    "Your other eGifts were created successfully";
  if (msg === undefined) return false;
  return msg.indexOf(PARTIAL_SUCCESS_KEY_TERM_FROM_SCION) > -1;
}

export function getPartialSuccessErrorMessage(msg: string | undefined) {
  if (!isPartialSuccessMessage(msg)) {
    throw new AllPurchasesFailedError();
  }
  const msgString = msg as string;
  const msgArray = msgString.split(" ");
  const firstWord = msgArray[0];
  if (firstWord.match(/^\d/)) {
    return formatPartiallySuccessfulOrderErrorMessage(msgString, firstWord);
  }
  return toWalletTerms(msgString);
}

function formatPartiallySuccessfulOrderErrorMessage(
  msg: string,
  firstWord: string
) {
  let replaceWith = "these";
  if (firstWord == "1") {
    replaceWith = "it";
  }
  msg = msg.replace(/that one/gi, replaceWith);
  return toWalletTerms(msg);
}

function toWalletTerms(msg: string) {
  const oldWords = /eGifts|eGift|created/gi;
  return msg.replace(oldWords, function (key) {
    // @ts-ignore
    return oldToNewWords[key];
  });
}

const oldToNewWords = {
  eGifts: "assets",
  eGift: "assets",
  created: "generated",
};

export class AllPurchasesFailedError extends Error {}
