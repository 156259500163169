<template>
  <svg
    class="animate-spin"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9C1 13.4183 4.58172 17 9 17V17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
