<script setup lang="ts">
import { ref } from "vue";
// @ts-ignore
import VLazyImage from "v-lazy-image";
import { SelectLinkProduct } from "../../api.generated/scion";
import { useBasketStore } from "../../stores";

const IMAGE_CLOUDFLARE_URL = import.meta.env.VITE_IMAGE_CLOUDFLARE_URL;

const { product, displayName } = defineProps<{
  product: SelectLinkProduct;
  displayName?: boolean;
  useBackgroundColor?: boolean;
  classImage?: string;
}>();

const backgroundImage = `${IMAGE_CLOUDFLARE_URL}/${product.code}-icon/public`;

const fallbackBgImage = product.iconImageUrl ?? "";

const basketStore = useBasketStore();
let imageURL = ref<string>(backgroundImage);

const emit = defineEmits(["productSelected"]);

function onClick() {
  if (!basketStore.isOrderProcessing) {
    emit("productSelected", product);
  }
}
</script>

<template>
  <button
    @click="onClick"
    :class="[product.code]"
    :data-product-code="product.code"
  >
    <div
      v-if="useBackgroundColor"
      :style="{ backgroundColor: '#FFFFFF' }"
      class="custom-shadow relative rounded-2xl p-6"
    >
      <v-lazy-image
        class="max-h-[84px]"
        :src="imageURL"
        :class="classImage"
        :alt="product.name"
        src-placeholder="/static/img/product-img-placeholder.png"
        @error="imageURL = fallbackBgImage"
      />
      <div class="gradient-overlay rounded-2xl"></div>
    </div>
    <div class="custom-shadow relative rounded-lg" v-else>
      <v-lazy-image
        class="max-h-fit rounded-lg"
        :class="classImage"
        :src="imageURL"
        :alt="product.name"
        src-placeholder="/static/img/product-img-placeholder.png"
        @error="imageURL = fallbackBgImage"
      />
      <div class="gradient-overlay rounded-lg"></div>
    </div>
    <div v-if="displayName" class="mt-2 truncate text-center text-xs">
      {{ product.name }}
    </div>
  </button>
</template>

<style scoped>
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top right,
    rgb(255, 255, 255, 0),
    rgb(255, 255, 255, 0),
    rgb(255, 255, 250, 0.4)
  );
}
.custom-shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1),
    0px 2px 8px -2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 300ms ease-in-out;
}
.custom-shadow:hover {
  box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.2),
    0px 2px 8px -2px rgba(0, 0, 0, 0.2);
}
</style>
