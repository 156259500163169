import { defineStore } from "pinia";

type NavigationState = {
  purchaseSuccess: boolean;
  numberOfAssets: number;
};

export const useNavigationStore = defineStore("navigation", {
  state: () =>
    ({
      purchaseSuccess: false,
      numberOfAssets: 0,
    } as NavigationState),
  getters: {
    getPurchaseSuccess(): boolean {
      return this.purchaseSuccess;
    },
    getNumberOfAssets(): number {
      return this.numberOfAssets;
    },
  },
  actions: {
    setPurchaseSuccess(value: boolean) {
      this.purchaseSuccess = value;
    },
    setNumberOfAssets(value: number) {
      this.numberOfAssets = value;
    },
  },
});
