<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { getCurrentCredit } from "../../utils/stores/utils";

const linkDetailsStore = useLinkDetailsStore();
const isStickyHeaderVisible = ref<boolean>(false);

const handleScroll = () => {
  isStickyHeaderVisible.value = window.pageYOffset > 200;
};

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div
    v-if="isStickyHeaderVisible"
    class="t-0 customisations fixed inset-x-0 z-10 bg-white py-2 shadow-md"
    :style="{ '--bg-color': linkDetailsStore.customisation.backgroundColour }"
  >
    <div class="m-auto max-w-[73rem] px-6">
      <div class="px-0 md:px-6">
        <div class="font-f37lineca">Balance</div>
        <div class="font-f37lineca mt-1 text-2xl tracking-tight">
          {{ getCurrentCredit(linkDetailsStore) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customisations {
  background-color: var(--bg-color, #ffffff);
}
</style>
