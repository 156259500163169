<script setup lang="ts">
import { Modal, Basket } from "../index";
import { useBasketStore } from "../../stores";

const emit = defineEmits(["basketModalCloseClicked", "purchaseComplete"]);
const basketStore = useBasketStore();

function onCloseClick() {
  emit("basketModalCloseClicked");
}

function onPurchaseComplete(event: any) {
  emit("purchaseComplete", event);
}

function getBasketTitle() {
  if (basketStore.getNumberOfItems) {
    return `Basket (${basketStore.getNumberOfItems})`;
  }

  return "Basket";
}
</script>
<template>
  <Modal
    :title="getBasketTitle()"
    size="large"
    :closable="true"
    @onCloseClick="onCloseClick"
  >
    <template #body>
      <Basket
        @clickedAddMoreAssets="onCloseClick"
        @purchaseComplete="onPurchaseComplete"
      />
    </template>
    <template #button> </template>
  </Modal>
</template>
