import { PurchasedGift } from "../api.generated/scion";

export function getAssetToken(redeemUrl: string): string {
  const url = new URL(redeemUrl as string);
  const pathname = url.pathname.replace(/\/+$/, "");
  return pathname.slice(pathname.lastIndexOf("/") + 1);
}

export function sortPurchasedAssets(
  assetA: PurchasedGift,
  assetB: PurchasedGift
): number {
  const productAName = assetA.product.name.toLowerCase();
  const productBName = assetB.product.name.toLowerCase();

  if (productAName > productBName) {
    return 1;
  }

  if (productAName < productBName) {
    return -1;
  }

  return 0;
}
