/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SelectLinkOrderItemResult {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}
