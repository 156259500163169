<script setup lang="ts">
import { computed } from "vue";
import { PurchasedGift } from "../../api.generated/scion";
import { Product } from "../index";
import { RouteNames } from "../../utils/routes";
import { sortPurchasedAssets } from "../../utils/purchasedAsset";
import { useBasketStore } from "../../stores";

const props = defineProps<{
  purchasedAssets: PurchasedGift[];
}>();
const basketStore = useBasketStore();

const emit = defineEmits(["assetSelected"]);

const sortedAssets = computed(() => {
  return props.purchasedAssets.sort(sortPurchasedAssets);
});

const groupedAssets = computed((): PurchasedGift[][] => {
  const groups = {} as { [key: string]: PurchasedGift[] };

  sortedAssets.value.forEach((asset) => {
    if (!groups[asset.product.code]) {
      groups[asset.product.code] = [];
    }

    groups[asset.product.code].push(asset);
  });

  return Object.values(groups);
});

function onClick(asset: PurchasedGift) {
  emit("assetSelected", asset);
}
</script>

<template>
  <div class="rounded-2xl bg-grey-300 pl-4 pt-3 text-sm">
    <div class="mr-4 flex">
      <span class="flex-1 font-semibold">Your assets</span>

      <router-link
        :to="
          !basketStore.isOrderProcessing
            ? { name: RouteNames.Assets, query: $route?.query }
            : ''
        "
      >
        <span class="inline align-middle font-light">See all</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="inline h-4 w-4 align-middle"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </router-link>
    </div>

    <div class="flex cursor-grab items-center overflow-x-auto py-3">
      <div
        v-for="assets in groupedAssets"
        :key="assets[0].product.code"
        class="mr-4 max-h-full w-[105px] shrink-0 md:w-[142px] lg:w-[112px]"
      >
        <Product
          v-if="assets.length === 1"
          :product="assets[0].product"
          @productSelected="onClick(assets[0])"
        >
        </Product>

        <router-link
          v-else
          :to="{ name: RouteNames.Assets, query: $route?.query }"
          class="relative inline-block"
        >
          <Product :product="assets[0].product"> </Product>
          <span
            class="font-f37lineca custom-shadow absolute right-[-8px] top-[-8px] rounded-full border border-white bg-grey-800 px-[9px] py-[2px] text-base text-white"
          >
            {{ assets.length }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.13), 0px 9px 19px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);
}
</style>
