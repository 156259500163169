<template>
  <div class="cursor-pointer">
    <svg
      class="w-8 md:w-10"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" rx="20" fill="#F6F6F6" />
      <path
        d="M26 14L14 26"
        stroke="#333333"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M26 26L14 14"
        stroke="#333333"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>
