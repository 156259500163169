<script setup lang="ts">
import { event as gaEvent } from "vue-gtag";

import { Modal, AssetAmount, CollapsibleSection } from "../index";
import { SelectLinkProduct } from "../../api.generated/scion";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { useProductStore } from "../../stores/product";
import { getCurrencySymbol } from "../../utils/stores/utils";
import { ref, onMounted } from "vue";
import {
  MAX_NUMBER_OF_ITEMS,
  ProductSource,
  useBasketStore,
} from "../../stores/basket";
import Product from "../Product/index.vue";

const { product, productSource } = defineProps<{
  product: SelectLinkProduct;
  productSource: ProductSource;
}>();

const linkDetailsStore = useLinkDetailsStore();
const productStore = useProductStore();
const basketStore = useBasketStore();

let amountToPurchase = ref<number>(product.minimumValue);
const currentSymbol: string = getCurrencySymbol(linkDetailsStore, productStore);
let isAmountValid = ref<boolean>(false);

const emit = defineEmits([
  "assetAmountModalCloseClicked",
  "addToBasket",
  "cancelPurchase",
]);

function onAmountChanged(amount: number) {
  amountToPurchase.value = amount;
}

function isValidAmountSelected(isValid: boolean) {
  isAmountValid.value = isValid;
}

function onAddToBasketClick() {
  basketStore.addItem(product, amountToPurchase.value, productSource);
  emit("addToBasket");
}

function onCloseClick() {
  emit("assetAmountModalCloseClicked");
}

function isBasketFull() {
  return +basketStore.getNumberOfItems >= MAX_NUMBER_OF_ITEMS;
}

function addButtonStyle(primaryColour: string) {
  const baseStyle =
    "shadow-lg w-full p-3 text-white border rounded-xl transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:border-opacity-50 disabled:cursor-not-allowed text-sm my-6";
  if (!primaryColour) {
    return (
      baseStyle +
      " " +
      "border-black bg-black enabled:hover:bg-gray-800 focus:border-blue-500 focus:ring-blue-500"
    );
  } else {
    return baseStyle + " " + "customisations";
  }
}

onMounted(() => {
  gaEvent("view_item", {
    hostname: window.location.hostname,
    currency: linkDetailsStore.currency,
    items: [
      {
        id: product.code,
        name: product.name,
        item_category: productSource as string,
      },
    ],
  });
});
</script>
<template>
  <Modal
    :title="product.name"
    size="large"
    :closable="true"
    @onCloseClick="onCloseClick"
  >
    <template #body>
      <div class>
        <div class="flex justify-center">
          <Product
            :product="product"
            class="rounded-2xl"
            :use-background-color="true"
          />
        </div>
      </div>
      <AssetAmount
        class="mt-4"
        :product="product"
        @amountChanged="onAmountChanged"
        @isValidAmountSelected="isValidAmountSelected"
      ></AssetAmount>
      <div v-if="isBasketFull()" class="mt-1 text-sm text-red-500">
        You cannot have more than {{ MAX_NUMBER_OF_ITEMS }} selections at a
        time.
      </div>
      <button
        :class="addButtonStyle(linkDetailsStore.customisation.primaryColour)"
        :style="{ '--bg-color': linkDetailsStore.customisation.primaryColour }"
        @click="onAddToBasketClick"
        :disabled="!isAmountValid || isBasketFull()"
      >
        Add {{ currentSymbol }}{{ amountToPurchase }} for {{ product.name }}
      </button>
      <CollapsibleSection
        v-if="product.redemptionInstructions"
        title="How to use"
        :text="product.redemptionInstructions"
        :expand-default="product.redemptionInstructions ? true : false"
      ></CollapsibleSection>
      <CollapsibleSection
        v-if="product.description"
        title="About"
        :text="product.description"
        :expand-default="product.redemptionInstructions ? false : true"
      ></CollapsibleSection>
      <CollapsibleSection
        v-if="product.consumerTerms"
        title="Terms & conditions"
        :text="product.consumerTerms"
        :expand-default="
          product.description || product.redemptionInstructions ? false : true
        "
      ></CollapsibleSection>
    </template>
  </Modal>
</template>

<style scoped>
.customisations {
  background-color: var(--bg-color);
  border: 1px solid var(--bg-color);
}
</style>
