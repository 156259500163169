<script setup lang="ts">
import { ref, computed } from "vue";
import { Modal } from "..";

const props = defineProps<{
  title: string;
  options: Array<{ name: string; code: string }>;
}>();

const emit = defineEmits(["searchCategorySelected"]);

const isModalOpen = ref<boolean>(false);
const selectedOptionCode = ref<string>(props.options[0].code);

const selectedOption = computed(() => {
  return props.options.find((o) => o.code === selectedOptionCode.value)?.name;
});

function toggleModal() {
  isModalOpen.value = !isModalOpen.value;
}

function selectOption(code: string) {
  selectedOptionCode.value = code;
  toggleModal();
  emit("searchCategorySelected", code);
}
</script>

<template>
  <div>
    <button
      @click="toggleModal"
      class="flex w-full items-center justify-center rounded-lg bg-grey-300 px-4 py-3"
    >
      <font-awesome-icon
        class="mr-1.5"
        :icon="['far', 'bars-filter']"
        size="xl"
      />
      <span class="truncate text-ellipsis font-semibold">
        {{ selectedOption }}
      </span>
    </button>

    <Modal
      v-if="isModalOpen"
      @onCloseClick="toggleModal"
      :title="title"
      overlay
      closable
      size="large"
    >
      <template #body>
        <ul>
          <li
            v-for="option in options"
            :key="option.code"
            @click="selectOption(option.code)"
            class="flex cursor-pointer items-center border-b border-grey-300 py-4 last:border-0"
          >
            <span class="flex-1">
              {{ option.name }}
            </span>

            <div v-if="option.code === selectedOptionCode">
              <font-awesome-icon
                class="my-2 ml-2 text-black"
                :icon="['far', 'check']"
                size="xl"
              />
            </div>
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>
