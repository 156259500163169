<script setup lang="ts">
import { useLinkDetailsStore } from "../../stores/linkDetails";
import {
  getRemainingDuration,
  getCurrentCredit,
} from "../../utils/stores/utils";
import { formatDateWithUserLocale } from "../../utils/format";

const linkDetailsStore = useLinkDetailsStore();

const expiryDateString = linkDetailsStore.expiryDate as string;
</script>

<template>
  <div>
    <div
      v-if="!linkDetailsStore.logoUrl.endsWith('placeholder.png')"
      class="mb-6"
    >
      <img :src="linkDetailsStore.logoUrl" alt="Logo" />
    </div>
    <div class="font-f37lineca">Balance</div>
    <div class="font-f37lineca text-[42px] leading-[48px] tracking-tight">
      {{ getCurrentCredit(linkDetailsStore) }}
    </div>
    <div class="text-xs font-extralight">
      <span v-if="!linkDetailsStore.isLinkExpired">
        Expires in {{ getRemainingDuration(expiryDateString) }}
      </span>
      <span v-else>
        Your balance expired on {{ formatDateWithUserLocale(expiryDateString) }}
      </span>
    </div>
  </div>
</template>

<style scoped>
.text-balance {
  font-size: 42px;
}
</style>
