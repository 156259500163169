<script setup lang="ts">
import { SelectLinkProduct } from "../../api.generated/scion";
import { Product } from "../index";

defineProps<{
  popularAssets: SelectLinkProduct[];
}>();

const emit = defineEmits(["assetSelected"]);

function onClick(asset: SelectLinkProduct) {
  emit("assetSelected", asset);
}
</script>

<template>
  <div>
    <div class="mb-3 text-sm font-semibold">
      <h2>Featured</h2>
    </div>
    <div class="flex cursor-grab overflow-x-auto pb-2">
      <Product
        v-for="asset in popularAssets"
        :key="asset.code"
        :product="asset"
        @productSelected="onClick(asset)"
        class="mr-4 max-h-full w-[105px] shrink-0 md:w-[142px] lg:w-[112px]"
        display-name
        class-image="max-h-[100px] w-full"
      >
      </Product>
    </div>
  </div>
</template>
