<script setup lang="ts">
import { ref, computed } from "vue";
import { ProductCategory, SelectLinkProduct } from "../../api.generated/scion";
import { Product, Filter, TwoTabs, Tab, Sort } from "../index";
import { ASSET_TYPES } from "../../utils/constants";
import { productCategories } from "../../utils/categories";
import {
  SortOption,
  sortOptions,
  SORT_ASCENDING_CODE,
  SORT_DESCENDING_CODE,
  SORT_RECOMMENDED_CODE,
} from "../../utils/sortOption";
import { useLinkDetailsStore } from "../../stores";

interface FilterableTab extends Tab {
  assets: SelectLinkProduct[];
  categories: ProductCategory[];
  selectedFilter: string;
  sortingOptions: SortOption[];
  selectedSortOption: string;
}

const props = defineProps<{
  products: SelectLinkProduct[];
}>();

defineEmits(["assetSelected"]);

const ALL_FILTER_CODE = "all";

let sortTitle = ref<string>("Sort gift cards");
let filteredAssets = ref<Array<SelectLinkProduct>>([]);

const linkDetailsStore = useLinkDetailsStore();

const recommendedAssets = computed(() => {
  return (
    import.meta.env[
      `VITE_RECOMMENDED_ASSET_CODES_${linkDetailsStore.currency}`
    ] || ""
  )
    .split(",")
    .map((code: string) => code.trim())
    .filter((code: string) => code);
});

const uniqueAssetTypes = computed(() => {
  const allAssetTypes = props.products.flatMap((x) => x.assetTypes);
  const distinctAssetTypes = new Set(allAssetTypes).values();
  return Array.from(distinctAssetTypes);
});

const sortedAssetTypes = computed(() => {
  return Object.keys(ASSET_TYPES).filter((x) =>
    uniqueAssetTypes.value.includes(x)
  );
});

const tabs = ref<FilterableTab[]>(
  sortedAssetTypes.value.map((assetType, index) => {
    const assets = props.products.filter((p) =>
      p.assetTypes.includes(assetType)
    );

    const categories = [
      { name: "All categories", code: ALL_FILTER_CODE },
      ...productCategories(assets),
    ] as ProductCategory[];

    const sortingOptions = sortOptions();

    const isActive = index === 0;

    return {
      label: toAssetLabel(assetType),
      target: assetType,
      selectedFilter: ALL_FILTER_CODE,
      assets,
      categories,
      sortingOptions,
      selectedSortOption: SORT_RECOMMENDED_CODE,
      isActive,
    } as FilterableTab;
  })
);

const currentlySelectedTab = computed(() => {
  return tabs.value.find((t) => t.isActive) as FilterableTab;
});

const filteredAndSortedAssets = computed(() => {
  let tab = tabs.value.find((t) => t.isActive) as FilterableTab;

  /**
   * Filter assets by category
   */

  if (tab.selectedFilter !== ALL_FILTER_CODE) {
    filteredAssets.value = tab.assets.filter((a) =>
      a.categories.includes(tab.selectedFilter)
    );
  } else {
    filteredAssets.value = tab.assets;
  }

  /**
   * Sort assets
   */

  switch (tab.selectedSortOption) {
    case SORT_RECOMMENDED_CODE:
      filteredAssets.value = filteredAssets.value.sort((a, b) => {
        const aIndex = recommendedAssets.value.indexOf(a.code);
        const bIndex = recommendedAssets.value.indexOf(b.code);

        if (aIndex === -1 && bIndex === -1) {
          return 0;
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      });
      break;
    case SORT_ASCENDING_CODE:
      filteredAssets.value = filteredAssets.value.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      break;
    case SORT_DESCENDING_CODE:
      filteredAssets.value = filteredAssets.value.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
      break;
  }

  return filteredAssets.value;
});

function sortSelected(option: string) {
  currentlySelectedTab.value.selectedSortOption = option;
}

function filterByCategory(selected: string) {
  currentlySelectedTab.value.selectedFilter = selected;
}

function toggleTo(tab: FilterableTab) {
  if (currentlySelectedTab.value.target !== tab.target) {
    currentlySelectedTab.value.isActive = false;

    tabs.value.forEach((t) => {
      if (t.target === tab.target) {
        t.isActive = true;
      }
    });
  }
}

function toAssetLabel(assetType: string) {
  return ASSET_TYPES[assetType as keyof typeof ASSET_TYPES];
}

function assetTypeSelected(selectedAssetType: string) {
  sortTitle.value = "Sort " + selectedAssetType.toLowerCase();
}
</script>

<template>
  <div>
    <TwoTabs
      @toggleTo="toggleTo"
      :tabs="tabs"
      @assetTypeSelected="assetTypeSelected"
    >
      <template v-for="tab in tabs" v-slot:[tab.target]>
        <div class="relative my-4 flex flex-row md:my-6">
          <Filter
            class="flex-1 pr-1 text-xs"
            title="Categories"
            :options="tab.categories"
            @searchCategorySelected="filterByCategory"
          >
          </Filter>
          <Sort
            class="flex-1 pl-1 text-xs"
            :title="sortTitle"
            @assetTypeSelected="assetTypeSelected"
            @sortSelected="sortSelected"
          >
          </Sort>
        </div>

        <div class="grid grid-cols-3 gap-4 md:grid-cols-4 lg:grid-cols-5">
          <Product
            v-for="product in filteredAndSortedAssets"
            :key="product.code"
            :product="product"
            @productSelected="$emit('assetSelected', product)"
            displayName
          >
          </Product>
        </div>
      </template>
    </TwoTabs>
  </div>
</template>
