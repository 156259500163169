<script setup lang="ts">
// @ts-ignore
import { defineProps } from "vue";
import { Modal } from "../index";
import { useLinkDetailsStore } from "../../stores/linkDetails";

const { numberOfAssets } = defineProps<{
  numberOfAssets: number;
}>();

const linkDetailsStore = useLinkDetailsStore();

const emit = defineEmits(["onAssetSuccssfullyCreatedModalClosed"]);

function getTitle(numberOfAssets: number) {
  return `Your ${
    numberOfAssets > 1 ? "assets were" : "asset was"
  } successfully created`;
}

function onCloseClick() {
  emit("onAssetSuccssfullyCreatedModalClosed");
}

function okButtonStyle(primaryColour: string) {
  const baseStyle =
    "w-full font-semibold px-6 py-3 text-black border-2 rounded-xl transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50";
  if (!primaryColour) {
    return (
      baseStyle +
      " " +
      "border-black focus:border-blue-500 focus:ring-blue-500 hover:bg-gray-100"
    );
  } else {
    return baseStyle + " " + "customisations-ok";
  }
}
</script>
<template>
  <Modal
    size="small"
    :title="getTitle(numberOfAssets)"
    :closable="false"
    textAlign="center"
    :fullHeightMobile="false"
    classNameOverlay="px-6"
    classNameContent="rounded-2xl"
    headerImageFilePath="/static/img/tick-success.svg"
  >
    <template #button>
      <button
        :class="okButtonStyle(linkDetailsStore.customisation.primaryColour)"
        :style="{ '--bg-color': linkDetailsStore.customisation.primaryColour }"
        @click="onCloseClick"
      >
        Ok
      </button>
    </template>
  </Modal>
</template>

<style scoped>
.customisations-ok {
  border: solid var(--bg-color);
  color: var(--bg-color);
}
</style>
