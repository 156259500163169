<script setup lang="ts">
import { Modal } from "../index";

import { useLinkDetailsStore } from "../../stores/linkDetails";

const emit = defineEmits(["removeAssetFromBasket", "closeRemoveAssetModal"]);

const { itemName, itemId } = defineProps<{
  itemName: string;
  itemId: string;
}>();

const linkDetailsStore = useLinkDetailsStore();

function onClickRemove() {
  emit("removeAssetFromBasket", itemId);
}

function onCloseClick() {
  emit("closeRemoveAssetModal");
}

function removeButtonStyle(primaryColour: string) {
  const baseStyle =
    "flex-1 mr-2 disabled:bg-gray-700 disabled:border-gray-700 disabled:pr-7 w-full px-6 py-3 text-white border-2 rounded-xl transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50 drop-shadow-lg";
  if (!primaryColour) {
    return (
      baseStyle +
      " " +
      "border-black bg-black focus:border-blue-500 focus:ring-blue-500 hover:bg-gray-800"
    );
  } else {
    return baseStyle + " " + "customisations-remove";
  }
}

function cancelButtonStyle(primaryColour: string) {
  const baseStyle =
    "flex-1 disabled:opacity-50 disabled:border-gray-700 disabled:text-gray-700 w-full px-6 py-3 text-black border-2 rounded-xl transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50";
  if (!primaryColour) {
    return (
      baseStyle +
      " " +
      "border-black focus:border-blue-500 focus:ring-blue-500 hover:bg-gray-100"
    );
  } else {
    return baseStyle + " " + "customisations-cancel";
  }
}
</script>
<template>
  <Modal
    title="Remove this asset?"
    :closable="true"
    size="small"
    @onCloseClick="onCloseClick"
    :fullHeightMobile="false"
    classNameOverlay="px-6"
    classNameContent="rounded-2xl"
  >
    <template #body>
      <p>{{ itemName }} will be removed from your Selection</p>
    </template>
    <template #button>
      <div class="flex w-full">
        <button
          :class="
            removeButtonStyle(linkDetailsStore.customisation.primaryColour)
          "
          :style="{
            '--bg-color': linkDetailsStore.customisation.primaryColour,
          }"
          @click="onClickRemove"
        >
          Remove
        </button>
        <button
          :class="
            cancelButtonStyle(linkDetailsStore.customisation.primaryColour)
          "
          :style="{
            '--bg-color': linkDetailsStore.customisation.primaryColour,
          }"
          @click="onCloseClick"
        >
          Cancel
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.customisations-remove {
  background-color: var(--bg-color);
  border: 1px solid var(--bg-color);
}
.customisations-cancel {
  border: solid var(--bg-color);
  color: var(--bg-color);
}
</style>
