import { SelectLinkProduct } from "../api.generated/scion";

export const MAX_POPULAR_ASSETS = 5;
export const MIN_POPULAR_ASSETS = 3;

export function generatePopularAssets(
  linkProducts: SelectLinkProduct[],
  popularProductCodes: string[]
): SelectLinkProduct[] {
  if (linkProducts.length < 5) {
    return [];
  }

  const filteredProducts: SelectLinkProduct[] = [];

  for (const code of popularProductCodes) {
    const productMatch = linkProducts.filter((product) => product.code == code);

    if (productMatch.length > 0) {
      filteredProducts.push(productMatch[0]);
    }
  }

  if (filteredProducts.length < MIN_POPULAR_ASSETS) {
    return [];
  } else if (filteredProducts.length <= MAX_POPULAR_ASSETS) {
    return filteredProducts;
  }

  return filteredProducts.slice(0, MAX_POPULAR_ASSETS);
}
