<script setup lang="ts">
import { useLinkDetailsStore, useProductStore } from "../../stores";
import { formatDateWithUserLocale } from "../../utils/format";
import { getCurrentCredit } from "../../utils/stores/utils";

const linkDetailsStore = useLinkDetailsStore();
const productStore = useProductStore();
const expiryDateString = linkDetailsStore.expiryDate as string;
</script>

<template>
  <div class="rounded-2xl bg-grey-300 p-4 md:p-6">
    <svg
      class="m-auto h-20 w-24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2548_19465)">
        <rect x="13" y="1" width="65" height="65" rx="32.5" fill="white" />
        <path
          d="M46 30V34H50"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M46 43C50.9706 43 55 38.9706 55 34C55 29.0294 50.9706 25 46 25C41.0294 25 37 29.0294 37 34C37 38.9706 41.0294 43 46 43Z"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M43 21H49"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M46 21V22"
          stroke="black"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2548_19465"
          x="0.872156"
          y="0.872156"
          width="89.2557"
          height="89.2557"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="11.8722"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_2548_19465"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2548_19465"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2548_19465"
            result="shape"
          />
        </filter>
      </defs>
    </svg>

    <h2 class="mb-2 text-center font-semibold">Your balance has expired</h2>
    <p class="text-center">
      Unfortunately your balance of
      {{ getCurrentCredit(linkDetailsStore) }} expired on
      {{ formatDateWithUserLocale(expiryDateString) }}
    </p>
  </div>
</template>
